import { ref, watch, computed } from "@vue/composition-api";
import axios from "@axios";
// Notification
import Vue from "vue";

export default function useBillersList() {
  // Use toast

  // const toast = useToast();


  // if (Designations) emit('addDesignation')
  const refListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    {
      label: " الاسم",
      key: "name",
      sortable: true,
    },
    
    {
      label: " number",
      key: "number",
      sortable: true,
    },
    {
      label: "المانح  ",
      key: "donor.name",
      sortable: true,
    },

    {
      label: "الخيارات",
      key: "actions",
      sortable: false,
    },
  
  ];
  const perPage = ref(5);
  const total = ref(0);
  const search = ref('');
  const currentPage = ref(1);
  const perPageOptions = [5,10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value
      ? refListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: total.value,
    };
  });
  
  const refetchData = () => {
    refListTable.value.refresh();
  };

  watch([currentPage, perPage, search], () => {
    refetchData();
  });


  const fetchData = (ctx, callback) => {
    
    return new Promise((resolve, reject) => {
      let url=`/api/v1/donor-biller`
      axios
      .get(url, {
        params: {
            search: search.value, // Assuming searchQuery.value holds the search query
            page_size: perPage.value, // Assuming perPage.value holds the number of items per page
            page: currentPage.value // Assuming currentPage.value holds the current page number
        }
    })
        .then(response => {
          const { data} = response.data;
      // console.log(response.data.data)
          callback(data);
          total.value = response.data.total;
        })
        .catch(error => {
          Vue.swal({
            title: "حدثت مشكلة في استرجاع البيانات",
    icon: "error",
         
      confirmButtonText: "موافق",
      customClass: {
        confirmButton: "btn btn-primary",
      },
      buttonsStyling: false,
    });
    
         
        })
    })
   
      
  
       
      
  };


  return {
    fetchData,
    search,
    tableColumns,
    perPage,
    currentPage,
    total,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,

    refetchData,
  };
}
